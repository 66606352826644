import React, { useEffect, useState } from 'react'
import { Center, Chip, ChipGroup, Container, Grid, Group, Loader } from '@mantine/core';
import styles from './brandNotifications.module.css';
import { BNotificationCard } from '../../components/bNotificationCard/BNotificationCard';
import { use } from 'react';
import { collection, getDocs, orderBy, query } from 'firebase/firestore';
import { db } from '../../firebase-config';

export function BrandNotifications() {
    const [loading, setLoading] = useState(false);
    const [warning, setWarning] = useState('Please Select a Brand');
    const [allNotifications, setAllNotifications] = useState([]);
    const [filter, setFilter] = useState('All');
    const filterOptions = ['All', 'Pending', 'Sent', 'Rejected'];

    useEffect(() => {
        setLoading(true);
        fetchNotifications();
    }, []);

    const fetchNotifications = async () => {
        try {
            const querySnapshot = await getDocs(collection(db, 'brandConfigs', 'brandNotifications', 'notifications'));
            const notifications = querySnapshot.docs.map(doc => ({ notificationId: doc.id, ...doc.data() }));
            notifications.sort((a, b) => new Date(b.createdAt.toDate()) - new Date(a.createdAt.toDate()));
            setAllNotifications(notifications);
            setLoading(false);
        } catch (err) {
            setWarning(err.message);
            setLoading(false);
        }
        setLoading(false);
    }

    // let testNotification = {
    //     content: "This is the last notification test for the day.This is the last notification test for the day.",
    //     createdAt: "December 21, 2024 at 2:50:03 PM UTC - 6",
    //     image: "https://firebasestorage.googleapis.com/v0/b/loyaltea-952b1.appspot.com/o/brands%2Fwj7a25227zgeGFfucPyy%2Fnotifications%2Fb72J1dizeD5zhXGHmD9r%2F1734814199183.png?alt=media&token=c53509cb-6d42-4d25-b662-29b333286bed",
    //     scheduledAt: "December 21, 2024 at 2:55:00 PM UTC - 6",
    //     senderId: "wj7a25227zgeGFfucPyy",
    //     senderImage: "https://firebasestorage.googleapis.com/v0/b/loyaltea-952b1.appspot.com/o/brands%2Fwj7a25227zgeGFfucPyy%2Flogo%2F1726816924138.png?alt=media&token=617bfc90-871d-42a7-966e-fad672d3e04e",
    //     senderName: "Tiara Unisex Salon",
    //     sentAt: "December 21, 2024 at 2:55:17 PM UTC - 6",
    //     status: "sent",
    //     target: "wj7a25227zgeGFfucPyy",
    //     targetType: "allClients",
    //     title: "This is again a scheduled notification.",
    //     type: 0,
    //     updatedAt: "December 21, 2024 at 2:50:13 PM UTC - 6"
    // }

    return (
        <Container
            size="xxl"
            style={{ padding: '20px 60px' }}
            className={styles.container}
        >
            {loading ? (
                <Center>
                    <Loader />
                </Center>
            ) : (
                <div>
                    <h1>Brand Notifications</h1>
                    <Group position="center" style={{ marginBottom: 20 }}>
                        <ChipGroup value={filter} onChange={setFilter}>
                            {filterOptions.map((option) => (
                                <Chip key={option} value={option}>
                                    {option}
                                </Chip>
                            ))}
                        </ChipGroup>
                    </Group>
                    <Grid>
                        {allNotifications.filter(notification => filter === 'All' || notification.status === filter.toLowerCase()).map(notification => (
                            <BNotificationCard notification={notification} />
                        ))}
                    </Grid>
                </div>
            )}
        </Container>
    )
}