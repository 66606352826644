import { Avatar, Table, Group, Text, ActionIcon, rem, Loader, Badge, UnstyledButton, Center } from '@mantine/core';
import { IconPencil, IconTrash, IconChevronUp, IconChevronDown, IconSelector } from '@tabler/icons-react';
import { useState } from 'react';
import { EditBrand } from '../editBrandModal/EditBrandModal';
import { deleteDoc, doc } from 'firebase/firestore';
import { db } from '../../firebase-config';
import { ConfirmBox } from '../confirmBox/ConfirmBox';

// Add this helper function for sorting
function Th({ children, sorted, onSort }) {
    const Icon = sorted === 'asc' ? IconChevronUp 
               : sorted === 'desc' ? IconChevronDown 
               : IconSelector;
    
    return (
        <Table.Th>
            <UnstyledButton onClick={onSort} style={{ display: 'flex', alignItems: 'center' }}>
                <Text fw={500} fz="sm">{children}</Text>
                {onSort && (
                    <Center style={{ marginLeft: '4px' }}>
                        <Icon size={14} stroke={1.5} />
                    </Center>
                )}
            </UnstyledButton>
        </Table.Th>
    );
}

export function BrandsTable({ data, filter, loading, error, fetchLatestData }) {
    const [selectedBrand, setSelectedBrand] = useState({});
    const [openBrandDetails, setOpenBrandDetails] = useState(false);
    const [sortBy, setSortBy] = useState(null);
    const [sortOrder, setSortOrder] = useState(null);

    if (loading) {
        return <Loader />;
    }

    if (filter && filter !== 'All') {
        data = data.filter((item) => (item.brands).find((brand) => brand.id === filter));
    }

    const deleteBrand = async (brand) => {
        let docRef = doc(db, 'brands', brand.id);
        await deleteDoc(docRef);
        fetchLatestData();
    }

    // refactor data 
    data = data.map((item) => {
        return {
            ...item,
            actions: true,
        };
    });

    // Sorting function
    const setSorting = (field) => {
        if (sortBy === field) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : sortOrder === 'desc' ? null : 'asc');
            if (sortOrder === 'desc') {
                setSortBy(null);
            }
        } else {
            setSortBy(field);
            setSortOrder('asc');
        }
    };

    // Apply sorting to data
    let sortedData = [...data];
    if (sortBy && sortOrder) {
        sortedData.sort((a, b) => {
            if (sortBy === 'name') {
                return sortOrder === 'asc' 
                    ? a.name.localeCompare(b.name)
                    : b.name.localeCompare(a.name);
            }
            if (sortBy === 'status') {
                return sortOrder === 'asc'
                    ? (a.visible === b.visible ? 0 : a.visible ? 1 : -1)
                    : (a.visible === b.visible ? 0 : a.visible ? -1 : 1);
            }
            return 0;
        });
    }

    const rows = sortedData.map((item, index) => (
        <Table.Tr key={index}>
            <Table.Td>
                <Text fz="sm">{index + 1}</Text>
            </Table.Td>
            <Table.Td>
                <Group gap="sm">
                    <Avatar size={30} src={item.logo} radius={30} />
                    <Text fz="sm" fw={500}>
                        {item.name}
                    </Text>
                </Group>
            </Table.Td>
            <Table.Td>
                <Badge 
                    color={item.visible ? 'green' : 'gray'}
                    variant="light"
                >
                    {item.visible ? 'Visible' : 'Hidden'}
                </Badge>
            </Table.Td>

            {item.actions &&
                <Table.Td>
                    <Group gap={0} justify="flex-start">
                        <ActionIcon variant="subtle" color="gray" onClick={
                            () => {
                                setSelectedBrand(item);
                                setOpenBrandDetails(true);
                            }
                        }>
                            <IconPencil style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
                        </ActionIcon>
                        <ConfirmBox onConfirm={() => deleteBrand(item)} >
                            <ActionIcon variant="subtle" color="red" >
                                <IconTrash style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
                            </ActionIcon>
                        </ConfirmBox>
                    </Group>
                </Table.Td>
            }
        </Table.Tr>
    ));

    return (
        <>
            <Table.ScrollContainer minWidth={800}>
                <Table verticalSpacing="sm">
                    <Table.Thead>
                        <Table.Tr>
                            <Th>SN</Th>
                            <Th 
                                sorted={sortBy === 'name' ? sortOrder : null}
                                onSort={() => setSorting('name')}
                            >
                                Brand
                            </Th>
                            <Th 
                                sorted={sortBy === 'status' ? sortOrder : null}
                                onSort={() => setSorting('status')}
                            >
                                Status
                            </Th>
                            <Th>Actions</Th>
                        </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody>{rows}</Table.Tbody>
                </Table>
            </Table.ScrollContainer>

            <EditBrand brandData={selectedBrand} open={openBrandDetails} setOpen={setOpenBrandDetails} fetchLatestData={fetchLatestData} />
        </>
    );
}