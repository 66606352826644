import { Card, Avatar, Text, Group } from '@mantine/core';
import classes from './UserCard.module.css';

export function UserCard({ user }) {
    const stats = [
        { label: 'Stamps', value: user.stampsCount || 0 },
        { label: 'Rewards', value: user.rewardsCount || 0 },
        { label: 'Brands', value: user.brandsCount || 0 },
    ];

    const items = stats.map((stat) => (
        (stat.value > 0 || stat.label !== 'Brands') &&
        <div key={stat.label}>
            <Text ta="center" fz="lg" fw={500}>
                {stat.value || 0}
            </Text>
            <Text ta="center" fz="sm" c="dimmed" lh={1}>
                {stat.label}
            </Text>
        </div>
    ));

    return (
        <Card padding="xl" radius="md" className={classes.card}>
            <Card.Section
                h={50}
            // style={{
            //     backgroundImage:
            //         'url(https://images.unsplash.com/photo-1488590528505-98d2b5aba04b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=80)',
            // }}
            />
            <Avatar
                src={user.image}
                size={80}
                radius={80}
                mx="auto"
                mt={-30}
                className={classes.avatar}
            />
            <Text ta="center" fz="lg" fw={500} mt="sm">
                {user.name}
            </Text>
            <Text ta="center" fz="sm" c="dimmed">
                {user.phone}
            </Text>
            <Group mt="md" justify="center" gap={30}>
                {items}
            </Group>
            {/* <Button fullWidth radius="md" mt="xl" size="md" variant="default" color='red'>
                Delete User
            </Button> */}
        </Card>
    );
}