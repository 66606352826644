import React from 'react';
import { Card, Image, Text, Grid, Group, ThemeIcon, Chip, Center, Tooltip } from '@mantine/core';

const CoffeeStamp = ({ filled, image, color, stampDate }) => (
  <Tooltip 
    label={stampDate ? new Date(stampDate.seconds * 1000).toLocaleDateString() : ''} 
    disabled={!filled}
  >
    <ThemeIcon
      radius="xl"
      size="xl"
      color={filled ? color : 'gray'}
    >
      {filled &&
        <Image
          src={image}
          alt="stamp"
          style={{ width: 40, height: 40, backgroundColor: color, borderRadius: '50%' }}
        />
      }
    </ThemeIcon>
  </Tooltip>
);

export function StampCard({ stampCard }) {
  // const stampCard = {
  //   "brandId": "RbFSquUg4KEXLIyUgFAY",
  //   "image": "https://firebasestorage.googleapis.com/v0/b/loyaltea-952b1.appspot.com/o/brands%2FRbFSquUg4KEXLIyUgFAY%2Fstamps%2F1717922151810.png?alt=media&token=f8a07fd9-ab22-4b9a-b283-763a2e9db831",
  //   "color": "#008512",
  //   "reward": " Buy 7, get one free",
  //   "totalStamps": 7,
  //   "stampCardId": "oKe4BFlc6s9itcMWejss",
  //   "count": 2
  // }

  return (
    <Card withBorder shadow="sm" padding="lg" style={{ minWidth: 400, minHeight: 300 }} radius={20}>
      <Group align='center' style={{ marginBottom: 10 }}>
        <Image
          src={stampCard.brand.logo}
          height={50}
          width={50}
        />
        <div>
          <Text fw={500} size="lg">{stampCard.brand.name}</Text>
          <Text size="sm" fw={400} fs={'italic'}>{stampCard.reward}</Text>
        </div>
      </Group>

      <Grid mt="md" align='center' justify='center'>
        {Array.from({ length: stampCard.totalStamps }).map((_, index) => (
          <Grid.Col span={3} key={index} align='center' justify='center'>
            <CoffeeStamp 
              filled={index < stampCard.count} 
              image={stampCard.image} 
              color={stampCard.color}
              stampDate={stampCard.stamps?.[index]?.stampedDate}
            />
          </Grid.Col>
        ))}
      </Grid>

      {stampCard.count === stampCard.totalStamps ? (
        <Center>
          <Chip checked={true} style={{ marginTop: 30 }} color={stampCard.color} variant="light">Ready to Claim</Chip>
        </Center>
      ) : (
        <Center>
          <Chip checked={false} style={{ marginTop: 30 }} color={stampCard.color} variant="light">{stampCard.totalStamps - stampCard.count} more to go</Chip>
        </Center>
      )}
    </Card>
  );
};
