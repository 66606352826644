import { Avatar, Badge, Table, Group, Text, ActionIcon, rem, Loader } from '@mantine/core';
import { IconPencil, IconTrash } from '@tabler/icons-react';
import { User } from '../../pages';
import { useState } from 'react';

export function UsersTable({ data, filter, loading, error }) {
    const [selectedUser, setSelectedUser] = useState({});
    const [openUserDetails, setOpenUserDetails] = useState(false);

    if (loading) {
        return <Loader />;
    }

    if (filter && filter !== 'All') {
        data = data.filter((item) => (item.brands).find((brand) => brand.id === filter));
    }

    // refactor data 
    data = data.map((item) => {
        return {
            ...item,
            actions: false,
        };
    });

    console.log(data);

    const rows = data.map((item, index) => (
        <Table.Tr key={index} style={{ cursor: 'pointer' }} onClick={
            () => {
                setSelectedUser(item);
                setOpenUserDetails(true);
            }
        }>
            <Table.Td>
                <Text fz="sm">{index + 1}</Text>
            </Table.Td>
            <Table.Td>
                <Group gap="sm">
                    <Avatar size={30} src={item.image} radius={30} />
                    <Text fz="sm" fw={500}>
                        {item.name}
                    </Text>
                </Group>
            </Table.Td>

            <Table.Td>
                <Text fz="sm">{item.phone}</Text>
            </Table.Td>

            <Table.Td>
                <Badge variant="dark" size='lg'>
                    {item.stampsCount}
                </Badge>
            </Table.Td>

            <Table.Td>
                <Badge variant="dark" size='lg'>
                    {item.rewardsCount}
                </Badge>
            </Table.Td>

            <Table.Td>
                <Badge variant="dark" size='lg'>
                    {item.brandsCount}
                </Badge>
            </Table.Td>

            {item.actions &&
                <Table.Td>
                    <Group gap={0} justify="flex-end">
                        <ActionIcon variant="subtle" color="gray">
                            <IconPencil style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
                        </ActionIcon>
                        <ActionIcon variant="subtle" color="red">
                            <IconTrash style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
                        </ActionIcon>
                    </Group>
                </Table.Td>
            }
        </Table.Tr>
    ));

    return (
        <>
            <Table.ScrollContainer minWidth={800}>
                <Table verticalSpacing="sm">
                    <Table.Thead>
                        <Table.Tr>
                            <Table.Th>SN</Table.Th>
                            <Table.Th>User</Table.Th>
                            <Table.Th>Phone</Table.Th>
                            <Table.Th>Stamps</Table.Th>
                            <Table.Th>Rewards</Table.Th>
                            <Table.Th>Brands</Table.Th>
                            <Table.Th>Actions</Table.Th>
                        </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody>{rows}</Table.Tbody>
                </Table>
            </Table.ScrollContainer>

            <User userData={selectedUser} open={openUserDetails} setOpen={setOpenUserDetails} />
        </>
    );
}