import { Avatar, Button, Chip, Grid, Group, Image, Paper, ScrollArea, Stack, Text, Title, Tooltip, TypographyStylesProvider } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { formatDistanceToNow } from 'date-fns';
import classes from './BNotificationCard.module.css';
import { useState } from 'react';
import { IconBell, IconCheck, IconClock, IconMail, IconX } from '@tabler/icons-react';
import { db } from '../../firebase-config';
import { deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { ConfirmBox } from '../';

export function BNotificationCard({ notification }) {
    const [approving, setApproving] = useState(false);
    const [rejecting, setRejecting] = useState(false);
    const [deleting, setDeleting] = useState(false);

    function ApproveNotification(notificationId) {
        let data = {
            "data": {
                "notificationId": notificationId
            }
        }
        setApproving(true);
        fetch('https://sendnotification-tkrnzbphgq-el.a.run.app', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(data => {
                setApproving(false);
                notification.status = 'sent';
                console.log('Success:', data);
                notifications.show({
                    color: 'green',
                    title: 'Notification Approved',
                    autoClose: 5000
                });
            })
            .catch((error) => {
                setApproving(false);
                console.error('Error:', error);
                notifications.show({
                    color: 'red',
                    title: 'Error approving notification',
                    message: error,
                    autoClose: 5000
                });
            });
    }

    function RejectNotification(notificationId) {
        setRejecting(true);
        const docRef = doc(db, 'brandConfigs', 'brandNotifications', 'notifications', notificationId);
        updateDoc(docRef, {
            status: 'rejected'
        }).then(() => {
            setRejecting(false);
            notification.status = 'rejected';
            notifications.show({
                color: 'green',
                title: 'Notification Rejected',
                autoClose: 5000
            });
        }).catch((error) => {
            setRejecting(false);
            console.error('Error:', error);
            notifications.show({
                color: 'red',
                title: 'Error rejecting notification',
                message: error,
                autoClose: 5000
            });
        });
    }

    async function DeleteNotification(notificationId) {
        setDeleting(true);
        const docRef = doc(db, 'brandConfigs', 'brandNotifications', 'notifications', notificationId);
        const notificationRef = doc(db, 'notifications', notificationId);
        deleteDoc(docRef).then(async () => {
            if (notificationRef) {
                await deleteDoc(notificationRef);
            }
            // destroy the notification card
            notification.status = 'deleted';
            setDeleting(false);
            notifications.show({
                color: 'green',
                title: 'Notification Deleted',
                autoClose: 5000
            });
        }).catch((error) => {
            setDeleting(false);
            console.error('Error:', error);
            notifications.show({
                color: 'red',
                title: 'Error deleting notification',
                message: error,
                autoClose: 5000
            });
        });
    }

    return (
        notification.status !== 'deleted' && (
            <Grid.Col span={4} key={notification.notificationId}>
                <Paper withBorder radius="md" className={classes.notification}>
                    <Group>
                        <Avatar
                            src={notification.senderImage}
                            alt={notification.senderName}
                            radius="xl"
                        />
                        <Stack style={{ gap: 1 }}>
                            <Text fz="sm">
                                {notification.senderName}
                            </Text>
                            <Tooltip label={notification.createdAt.toDate().toLocaleString()} withArrow>
                                <Text fz="xs" c="dimmed">
                                    {formatDistanceToNow(notification.createdAt.toDate(), { addSuffix: true })}
                                </Text>
                            </Tooltip>
                            <Group style={{ marginTop: 5 }}>
                                {notification.type === 0 ? (
                                    <Chip size="xs" variant="light" icon={<IconBell size={12} />} color='blue' checked={true}>
                                        Notification
                                    </Chip>
                                ) : (
                                    <Chip size="xs" variant="light" icon={<IconMail size={12} />} color='blue' checked={true}>
                                        SMS
                                    </Chip>
                                )}
                                <Chip
                                    size="xs"
                                    color={notification.status === 'sent' ? 'green' : notification.status === 'pending' ? 'orange' : 'red'}
                                    icon={notification.status === 'sent' ? <IconCheck size={12} /> : notification.status === 'pending' ? <IconClock size={12} /> : <IconX size={12} />}
                                    checked={true}
                                    variant='light'
                                >
                                    {notification.status.toUpperCase()}
                                </Chip>
                                {notification.scheduledAt &&
                                    <Text fz="xs" c="dimmed">
                                        Schedule for {notification.scheduledAt.toDate().toLocaleString()} ({formatDistanceToNow(notification.scheduledAt.toDate().toLocaleString(), { addSuffix: true })})
                                    </Text>
                                }
                            </Group>
                        </Stack>
                    </Group>
                    <ScrollArea h={300}>
                        <TypographyStylesProvider className={classes.body}>
                            {notification.title && (
                                <Title order={3} className={classes.title}>
                                    {notification.title}
                                </Title>
                            )}
                            {notification.image && (
                                <Image src={notification.image} alt={notification.title} className={classes.image} h={200} w="auto" fit="contain" />
                            )}
                            {notification.content && (
                                <Text className={classes.content}>
                                    {notification.content}
                                </Text>
                            )}
                        </TypographyStylesProvider>
                    </ScrollArea>

                    <Group justify='flex-end' style={{ marginTop: 20 }}>
                        <Button size="xs" variant="light" color="green" onClick={() => ApproveNotification(notification.notificationId)} loading={approving} disabled={notification.status === 'sent' || notification.status === 'rejected'}>
                            Approve
                        </Button>
                        <Button size="xs" variant="light" color="red" disabled={notification.status === 'sent' || notification.status === 'rejected'} loading={rejecting} onClick={() => RejectNotification(notification.notificationId)}>
                            Reject
                        </Button>
                        <ConfirmBox onConfirm={() => DeleteNotification(notification.notificationId)} >
                            <Button size="xs" variant="filled" color="red" loading={deleting}>
                                Delete
                            </Button>
                        </ConfirmBox>
                    </Group>
                </Paper>
            </Grid.Col>
        )
    );
}