import { useContext, useEffect, useState, useCallback } from 'react';
import { Center, Tooltip, UnstyledButton, Stack, rem, Image, Select, Modal, Checkbox } from '@mantine/core';
import {
  IconBellRinging,
  IconBuildingStore,
  IconGauge,
  IconLogout,
  IconQrcode,
  IconSwitchHorizontal,
  IconUsers
} from '@tabler/icons-react';
import { collection, getDocsFromServer, query } from 'firebase/firestore';
import { useDisclosure } from '@mantine/hooks';
import { useNavigate, useLocation } from 'react-router-dom';

import { AppContext } from '../../App';
import { db } from '../../firebase-config';
import classes from './NavBar.module.css';

// Move NavbarLink to separate component
function NavbarLink({ icon: Icon, label, active, onClick }) {
  return (
    <Tooltip label={label} position="right" transitionProps={{ duration: 0 }}>
      <UnstyledButton 
        onClick={onClick} 
        className={classes.link} 
        data-active={active || undefined}
      >
        <Icon style={{ width: rem(20), height: rem(20) }} stroke={1.5} />
      </UnstyledButton>
    </Tooltip>
  );
}

// Move static data outside
const NAV_ITEMS = [
  { icon: IconGauge, label: 'Dashboard', to: '/admin/dashboard' },
  { icon: IconBuildingStore, label: 'Brands', to: '/admin/brands' },
  { icon: IconUsers, label: 'Users', to: '/admin/users' },
  { icon: IconBellRinging, label: 'Notifications', to: '/admin/notifications' }
];

export function NavBar() {
  const [active, setActive] = useState(0);
  const { globalState, setGlobalState } = useContext(AppContext);
  const [opened, { open, close }] = useDisclosure(false);
  const [brands, setBrands] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const [showInvisibleBrands, setShowInvisibleBrands] = useState(false);

  // Move fetch logic to separate function
  const fetchBrands = useCallback(async () => {
    try {
      const q = query(collection(db, 'brands'));
      const querySnapshot = await getDocsFromServer(q);
      const fetchedBrands = querySnapshot.docs.map(doc => ({
        ...doc.data(),
        id: doc.id
      }));

      setBrands(fetchedBrands);
      setGlobalState(state => ({ ...state, brands: fetchedBrands }));

      // Validate currentBrand after fetching brands
      const savedBrand = localStorage.getItem('currentBrand');
      if (savedBrand && savedBrand !== 'All') {
        // Check if saved brand exists in fetched brands
        const brandExists = fetchedBrands.some(brand => brand.id === savedBrand);
        if (!brandExists) {
          // Reset to 'All' if saved brand no longer exists
          setGlobalState(state => ({ ...state, currentBrand: 'All' }));
          localStorage.setItem('currentBrand', 'All');
        }
      }
    } catch (error) {
      console.error('Error fetching brands:', error);
    }
  }, [setGlobalState]);

  useEffect(() => {
    const activeIndex = NAV_ITEMS.findIndex(link => link.to === location.pathname);
    setActive(activeIndex);
  }, [location.pathname]);

  useEffect(() => {
    if (globalState.brands.length === 0) {
      fetchBrands();
    }
  }, [globalState.brands.length, fetchBrands]);

  const handleBrandChange = (value) => {
    if (value) {
      setGlobalState(state => ({ ...state, currentBrand: value }));
      localStorage.setItem('currentBrand', value);
    }
    close();
  };

  const getBrandLogo = () => {
    if (globalState.currentBrand !== 'All' && globalState.brands.length !== 0) {
      const currentBrand = globalState.brands.find(brand => brand.id === globalState.currentBrand);
      return currentBrand?.logo;
    }
    return '/assets/logo_biz.png';
  };

  const handleNavigation = (path) => {
    navigate(path);
  };

  const handleLogout = () => {
    navigate('/admin/logout');
  };

  const getFilteredBrands = () => {
    if (showInvisibleBrands) {
      return brands;
    }
    return brands.filter(brand => {
      // If visible key doesn't exist, treat brand as invisible
      if (!('visible' in brand)) return false;
      if (brand.visible === false) return false;
      return brand.visible === true;
    });
  };

  const renderNavLinks = () => NAV_ITEMS.map((link, index) => (
    <NavbarLink
      {...link}
      key={link.label}
      active={index === active}
      onClick={() => {
        setActive(index);
        handleNavigation(link.to);
      }}
    />
  ));

  return (
    <nav className={classes.navbar}>
      <Center>
        <Image src={getBrandLogo()} alt="Brand logo" height={40} />
      </Center>

      <div className={classes.navbarMain}>
        <Stack justify="center" gap={0}>
          {renderNavLinks()}
        </Stack>
      </div>

      <Stack justify="center" gap={0}>
        <Modal opened={opened} onClose={close} title="Switch Brand" size="md" padding="md">
          <Stack>
            <Checkbox
              label="Show Hidden brands"
              checked={showInvisibleBrands}
              onChange={(event) => setShowInvisibleBrands(event.currentTarget.checked)}
              mb="sm"
            />
            <Select
              data={[
                ...getFilteredBrands().map(brand => ({ 
                  value: brand.id, 
                  label: brand.name + (!brand.visible ? ' (Hidden)' : '')
                })),
                { value: 'All', label: 'All' }
              ]}
              placeholder="Select Brand"
              value={globalState.currentBrand}
              searchable
              defaultValue={{ value: 'All', label: 'All' }}
              onChange={handleBrandChange}
              allowDeselect={false}
            />
          </Stack>
        </Modal>
        <NavbarLink 
          icon={IconQrcode} 
          label="Generate QRs" 
          onClick={() => handleNavigation('/admin/generateQrs')} 
        />
        <NavbarLink 
          icon={IconSwitchHorizontal} 
          label="Switch Brand" 
          onClick={open} 
        />
        <NavbarLink 
          icon={IconLogout} 
          label="Logout" 
          onClick={handleLogout} 
        />
      </Stack>
    </nav>
  );
}