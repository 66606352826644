import { Button, Checkbox, Container, Loader, Modal, Space, Text, TextInput } from "@mantine/core";
import { addDoc, collection, doc, updateDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../../firebase-config";

export const EditBrand = ({ brandData, open, setOpen, mode, fetchLatestData }) => {
    const [brand, setBrand] = useState({
        name: '',
        logo: '',
        visible: false,
        ...brandData
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [modalOpen, setModalOpen] = useState(false);

    const closeModal = () => {
        setModalOpen(false);
        setBrand({
            name: '',
            logo: '',
            visible: false,
            ...brandData
        });
    };

    useEffect(() => {
        if (open) {
            setModalOpen(true);
            setBrand({
                name: '',
                logo: '',
                visible: false,
                ...brandData
            });
        }
    }, [open, brandData]);

    async function onSave() {
        setLoading(true);
        setError('');

        try {
            if (mode === 'new') {
                let docRef = await addDoc(collection(db, 'brands'), {
                    name: brand.name,
                    logo: brand.logo,
                    visible: brand.visible,
                });
                brand.id = docRef.id;
            } else {
                let docRef = doc(db, 'brands', brand.id);
                await updateDoc(docRef, {
                    name: brand.name,
                    logo: brand.logo,
                    visible: brand.visible,
                });
            }
            fetchLatestData();
            closeModal();
            setOpen(false);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    }

    return (
        <Modal 
            opened={modalOpen || open} 
            onClose={() => {
                closeModal();
                setOpen(false);
            }} 
            title="Edit Brand" 
            size="md" 
            padding="md"
        >
            <div>
                {error && <Text color="red" align="center">{error}</Text>}
                {loading ? (
                    <Loader />
                ) : (
                    <Container size="xl">
                        <form onSubmit={(e) => {
                            e.preventDefault();
                            onSave();
                        }}>
                            {brand.id && <TextInput label="Brand ID" value={brand.id} disabled />}
                            <Space h="md" />
                            <TextInput
                                label="Brand Name"
                                placeholder="Enter brand name"
                                value={brand.name || ''}
                                onChange={(e) => setBrand({ ...brand, name: e.target.value })}
                                required
                            />
                            <Space h="md" />
                            <TextInput
                                label="Brand Logo"
                                placeholder="Enter brand logo"
                                value={brand.logo || ''}
                                onChange={(e) => setBrand({ ...brand, logo: e.target.value })}
                            />
                            <Space h="md" />
                            <Checkbox
                                label="Visible"
                                checked={!!brand.visible}
                                onChange={(e) => setBrand({ ...brand, visible: e.target.checked })}
                            />
                            <Space h="xl" />
                            <Button type="submit">Save</Button>
                        </form>
                    </Container>
                )}
            </div>
        </Modal>
    );
}