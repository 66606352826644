import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Login, Logout, Dashboard, AppDownload, Users, User, Brands, GenerateQRs, DetailDashboard, BrandNotifications, BizAppDownload } from './pages';
import { createTheme, MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import '@mantine/core/styles.css';
import '@mantine/notifications/styles.css';
import '@mantine/carousel/styles.css';
import { AppLayout } from './components';
import React, { useEffect } from 'react';

const theme = createTheme({
  colorScheme: 'light',
  primaryColor: 'yellow',
});

export const AppContext = React.createContext();

function App() {
  const [globalState, setGlobalState] = React.useState(() => {
    // Initialize state from localStorage if available
    const savedBrand = localStorage.getItem('currentBrand');
    return {
      currentBrand: savedBrand || "All",
      brands: [],
      stamps: [],
      rewards: [],
      users: []
    };
  });

  // Save currentBrand to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('currentBrand', globalState.currentBrand);
  }, [globalState.currentBrand]);

  return (
    <MantineProvider theme={theme}>
      <AppContext.Provider value={{ globalState, setGlobalState }}>
        <Notifications position="top-right" zIndex={1000} />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<AppDownload />} />
            <Route path="/biz" element={<BizAppDownload />} />
            <Route path="/admin" element={<AppLayout />} >
              <Route index element={<Login />} />
              <Route path="/admin/login" element={<Login />} />
              <Route path="/admin/logout" element={<Logout />} />
              <Route path="/admin/dashboard" element={<Dashboard />} />
              {/* <Route path="/admin/dashboard/brands" element={<DetailDashboard />} /> */}
              <Route path='/admin/notifications' element={<BrandNotifications />} />
              <Route path="/admin/brands" element={<Brands />} />
              <Route path="/admin/users" element={<Users />} />
              <Route path="/admin/users/:id" element={<User />} />

              <Route path="/admin/generateQRs" element={<GenerateQRs />} />
            </Route>
            <Route path="/app" element={<AppDownload />} />
            <Route path="*" element={<h1>404 Not Found</h1>} />
          </Routes>
        </BrowserRouter>
      </AppContext.Provider>
    </MantineProvider>
  );
}

export default App;
