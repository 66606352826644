import React, { useContext, useEffect, useRef, useState } from 'react'
import { QrCode } from 'react-qrcode-pretty'
import { AppContext } from '../../App';
import { Button, Center, Container, Group, Loader, NumberInput, Select, Space, Stack, Text } from '@mantine/core';
import { collection, doc, getDocFromServer, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../firebase-config';
import { AES, enc, lib, mode, pad } from 'crypto-js';
import styles from './generateQRs.module.css';
import { Carousel } from '@mantine/carousel';
import JSZip from 'jszip';
import { IconDownload, IconReload } from '@tabler/icons-react';
import { WEBSITE_URL } from '../../constants';

export function GenerateQRs() {
    const { globalState } = useContext(AppContext);
    const [loading, setLoading] = useState(false);
    const [warning, setWarning] = useState('Please Select a Brand');
    const [qrCodeValues, setQrCodeValues] = useState([]);
    const qrCodeImages = useRef([]);
    const qrCount = useRef(1);

    const [outletList, setOutletList] = useState([]);
    const [cardList, setCardList] = useState([]);
    const [selectedOutletId, setSelectedOutletId] = useState('');
    const [selectedCardId, setSelectedCardId] = useState('');
    const [cuurentBrandImageData, setCurrentBrandImageData] = useState('');

    async function getOutletList() {
        try {
            const outletSnapshot = await getDocs(collection(db, 'brands', globalState.currentBrand, 'outlets'));
            const outletList = outletSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setOutletList(outletList);
            setSelectedOutletId(outletList[0].id);
        } catch (error) {
            console.error('Failed to fetch outlet list:', error);
        }
    }

    async function getCardList() {
        try {
            const stampCardSnapshot = await getDocs(query(collection(db, 'stampCards'), where('brandId', '==', globalState.currentBrand)));
            const stampCardList = stampCardSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setCardList(stampCardList);
            setSelectedCardId(stampCardList[0].id);
        } catch (error) {
            console.error('Failed to fetch stamp card list:', error);
        }
    }

    useEffect(() => {
        async function fetchData() {
            setWarning('')
            await getOutletList();
            await getCardList();
            let brandImageData = 'data:image/png;base64, ' + await imageUrlToBase64(globalState?.brands?.find(brand => brand.id === globalState?.currentBrand)?.logo);
            setCurrentBrandImageData(brandImageData);
            setLoading(false);
        }

        if (globalState.currentBrand === "All" || globalState.brands.length === 0) {
            setWarning('Please Select a Brand')
        }
        else {
            setLoading(true);
            setWarning('')
            fetchData();
        }
    }, [globalState, globalState.currentBrand]);

    function encryptData(data, base64Key) {
        try {
            // Generate a random 16-byte IV
            const iv = lib.WordArray.random(16);

            // Convert the base64 key to WordArray
            const key = enc.Base64.parse(base64Key);

            // Encrypt the data using AES in CTR mode
            const encrypted = AES.encrypt(data, key, {
                iv: iv,
                mode: mode.CTR,
                padding: pad.Pkcs7
            });

            // The ciphertext is returned as a WordArray, so we need to convert it to base64
            const encryptedBase64 = enc.Base64.stringify(encrypted.ciphertext);
            const ivBase64 = iv.toString(enc.Base64);

            // Return the encrypted data and IV in base64 format
            return `${encryptedBase64}|${ivBase64}`;
        } catch (error) {
            console.error('Encryption failed:', error);
            throw error;
        }
    }

    async function generateQrCodes(count) {
        setLoading(true);
        let identifier = 'loy';
        // let newDocumentId = doc(db, 'stamps').id;

        // get the user's id
        const user = await getDocs(collection(db, 'brands', globalState.currentBrand, 'staffs'));
        const userIds = user.docs.map(doc => doc.id);
        const userId = userIds[0];

        // get the stamp card id
        const stampCardId = selectedCardId;

        // get the outlet id
        const outletId = selectedOutletId;

        let stampCount = 1;
        let minutesSinceEpoch = new Date().getTime() / 1000 / 60 + 525600;

        let docIds = Array(count).fill().map(() => doc(collection(db, 'stamps')).id);

        // get the brand secret
        const brandSecretQuery = await getDocFromServer(doc(db, 'brands', globalState.currentBrand, 'configs', 'secret'));
        const brandSecret = brandSecretQuery.data().key;

        let qrCodes = docIds.map((newDocumentId) => {
            let value = `${identifier}-${userId}-${globalState.currentBrand}-${stampCardId}-${outletId}+-=${encryptData(`${stampCount}-${minutesSinceEpoch}-${newDocumentId}`, brandSecret)}`;
            return value;
        });

        setQrCodeValues(qrCodes);
        setLoading(false);
    }

    function qrImages() {
        let components = qrCodeValues.map((qrCode, index) => {
            return (
                <Carousel.Slide key={index + "Slide"}>
                    <QrCode
                        key={index}
                        // image={`${WEBSITE_URL}/assets/brands/logo/${globalState.currentBrand}.png`}
                        value={qrCode}
                        variant={{
                            eyes: 'dots',
                            body: 'dots'
                        }}
                        padding={30}
                        modules={14}
                        level="H"
                        size={1024} // Increase the size to 1024 for higher resolution
                        onReady={(canvas) => canvasToImage(canvas)}
                        bgRounded
                    />
                </Carousel.Slide>
            )
        });
        return components;
    }

    async function canvasToImage(canvas) {
        // let mainImage = canvas.toDataURL('png');
        let logo = new Image();
        // logo.src = `${WEBSITE_URL}/assets/brands/logo/${globalState.currentBrand}.png`;
        logo.src = cuurentBrandImageData;
        logo.onload = () => {
            const context = canvas.getContext('2d');
            context.fillStyle = 'white';
            context.fillRect(422, 422, 240, 240);
            context.drawImage(logo, 422, 422, 240, 240);
            qrCodeImages.current.push(canvas.toDataURL('image/png'));
        }
    }

    const imageUrlToBase64 = async (url) => {
        const response = await fetch(url);
        const blob = await response.blob();
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                resolve(reader.result.split(',')[1]); // Return only the base64 part
            }
            reader.onerror = reject;
            reader.readAsDataURL(new Blob([blob], { type: 'image/png' }));
        });
    };

    // async function downloadAllQRs() {
    //     const zip = new JSZip();
    //     qrCodeImages.current.forEach((image, index) => {
    //         zip.file(`QR-${index + 1}.png`, image.split('base64,')[1], { base64: true });
    //     });

    //     const content = await zip.generateAsync({ type: 'blob' });
    //     saveAs(content, `QR-Codes-${globalState?.brands?.find(brand => brand.id === globalState?.currentBrand)?.name}.zip`);
    // }

    function downloadAllQRs() {
        const zip = new JSZip();
        qrCodeImages.current.forEach((image, index) => {
            zip.file(`QR-${index + 1}.png`, image.split('base64,')[1], { base64: true });
        });

        zip.generateAsync({ type: 'blob' }).then((content) => {
            const url = URL.createObjectURL(content);
            const link = document.createElement('a');
            link.href = url;
            link.download = `QR-Codes-${globalState?.brands?.find(brand => brand.id === globalState?.currentBrand)?.name}.zip`;
            link.click();
            URL.revokeObjectURL(url);
        });
    }

    return (
        <Container
            size="xxl"
            style={{ padding: '20px' }}
            className={styles.container}
        >
            {loading ? (
                <Center>
                    <Loader />
                </Center>
            ) : (
                globalState.currentBrand !== "All" ? (
                    qrCodeValues.length !== 0 ? (
                        <Stack gap={20} align='center' justify='center'>
                            <Text align="center" fz={20} fw={600}>Generate QR Codes for {globalState?.brands?.find(brand => brand.id === globalState?.currentBrand)?.name}</Text>
                            <Carousel style={{ width: '100%' }} slideSize="30%" align="start" dragFree withIndicators>
                                {qrImages()}
                            </Carousel>
                            <Group justify="center">
                                <Button
                                    variant="light"
                                    rightSection={<IconDownload size={14} />}
                                    onClick={downloadAllQRs}
                                >
                                    Download All
                                </Button>
                                <Button
                                    variant="light"
                                    rightSection={<IconReload size={14} />}
                                    onClick={() => {
                                        setQrCodeValues([])
                                        qrCodeImages.current = []
                                    }}
                                >
                                    Generate New
                                </Button>
                            </Group>
                        </Stack>
                    ) : (
                        <>
                            <Text align="center" fz={20} fw={600}>Generate QR Codes for {globalState?.brands?.find(brand => brand.id === globalState?.currentBrand)?.name}</Text>
                            <Space h={20} />
                            <Stack gap={20} align='center' justify='center'>
                                <NumberInput
                                    label="Number of QRs"
                                    placeholder="Enter number of QRs"
                                    min={1}
                                    max={200}
                                    defaultValue={qrCount.current}
                                    onChange={(value) => qrCount.current = value}
                                />
                                <Select
                                    searchable
                                    data={outletList.map((item) => ({ value: item.id, label: `${item.name}, ${item.address}` }))}
                                    value={selectedOutletId}
                                    label="Select Outlet"
                                    placeholder="Select Outlet"
                                    onChange={(value) => setSelectedOutletId(value)}
                                />
                                <Select
                                    searchable
                                    data={cardList.map((item) => ({ value: item.id, label: `${item.name} (${item.reward})` }))}
                                    value={selectedCardId}
                                    label="Select Card"
                                    placeholder="Select Card"
                                    onChange={(value) => setSelectedCardId(value)}
                                />
                                <Button
                                    disabled={selectedOutletId === '' || selectedCardId === ''}
                                    onClick={() => generateQrCodes(qrCount.current)}
                                    loading={loading}
                                >
                                    Generate
                                </Button>

                                <Text align="center" fz={14} c="red">Note: Please make sure to download the QR codes after generating them. Once you leave this page, you will not be able to download the QR codes again.</Text>
                            </Stack>
                        </>
                    )
                ) : (
                    <Center>
                        <h1>{warning}</h1>
                    </Center>
                )
            )
            }
        </Container>
    )
}